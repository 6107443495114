
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Button, Image } from "react-bootstrap";
import setting from "../setting.json";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { Link } from "react-router-dom";

const schema = yup.object().shape({
  otp: yup
    .string()
    .required('OTP is required'),

  newpassword: yup
    .string()
    .required('New Password is required '),
  cpassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref("newpassword")], "Passwords do not match"),
})
const ForgetPassword = () => {
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),

  })
  const ChangePassword = async (data) => {
    var rec = {
      "otp": data.otp,
      "newpassword": data.newpassword,
      "email": localStorage.getItem("tempuser"),


    }
    await fetch(setting.api + "/api/ChangePassword", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(rec),
      headers: {
        "Content-Type": "application/json",

      }
    })
      .then(response => response.json())
      .then(res => {
        if (res.result == "success") {
          localStorage.clear();
          navigate('/')

        } else {
          setMsg(res.reason);
        }

      }
      );
  }

  return (
    <>
      <main className="main-content  mt-0">
        <section>

          <div className="page-header min-vh-100">

            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                  <div className="card card-plain">

                    <div className="card-body">
                      <Image src="./assets/img/logos/Daily.webp" width={70} height={70} className='logo' />
                      <h2 className='gym'>Beast Gym</h2>
                      <h4 className="font-weight-bolder">Sign In</h4>
                      <Form onSubmit={handleSubmit(ChangePassword)}>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="example-text-input" className="form-control-label">OTP</label>
                            <input className="form-control" type="number"{...register("otp")} />
                            {errors.otp && <p>{errors.otp.message}</p>}

                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="example-text-input" className="form-control-label">New Password</label>
                            <input className="form-control" type="password" {...register("newpassword")} />
                            {errors.newpassword && <p>{errors.newpassword.message}</p>}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="example-text-input" className="form-control-label">Confirm Password </label>
                            <input className="form-control" type="password"{...register("cpassword")} />
                            {errors.cpassword && <p>{errors.cpassword.message}</p>}
                          </div>
                        </div>




                        <div className="text-center">
                          <Button type="submit" className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">Forget Password</Button>
                        </div>
                      </Form>



                    </div>

                  </div>
                </div>
                <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                  <div className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden signimg" >
                    <span className="mask bg-gradient-primary opacity-6"></span>
                    <h4 className="mt-5 text-white font-weight-bolder position-relative">"Attention is the new currency"</h4>
                    <p className="text-white position-relative">The more effortless the writing looks, the more effort the writer actually put into the process.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ForgetPassword;