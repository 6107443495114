import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";
import Form from 'react-bootstrap/Form';
import { Card, Col, Row, Button } from "react-bootstrap";
import Select from 'react-select'
import { useEffect, useState } from "react";
import setting from "../setting.json";
import React from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
    qty: yup.string().required('Enter youer qty'),
    units: yup.string().required('Enter youer units'),

})

const MemberDiet = () => {


    const [list, setList] = useState([]);
    const [foodList, setFoodList] = useState([]);
    const [memberDiet, setMemberDiet] = useState([]);
    const [member, setMember] = useState(null);
    const [dietList, setDietList] = useState(null);

    const {
        register,
        handleSubmit,

    } = useForm({
        resolver: yupResolver(schema),

    })

    const saveDiet = async (id) => {




        let md = {
            "id": id,
            "diet": [
                {
                    "food_item": memberDiet.id,
                    "qty": memberDiet.qty,
                    "units": memberDiet.units,
                },
            ]
        }
        await fetch(setting.api + "/api/saveDiet",
            {
                body: JSON.stringify(md),
                method: "post",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(
                dd =>
                    console.log(dd)
            )
    }

    const getMemberById = async (id) => {

        await fetch(setting.api + "/api/getMemberById?id=" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(
                response => response.json()
            )
            .then(
                (data) => {
                    console.log(data);
                    setMember(data.data);

                }
            );
        await fetch(setting.api + "/api/getMemberDietByMemberId?id=" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(a => {
                var res = a.data;
                setMemberDiet(JSON.parse(res.diet));

            }
            );
    }

    const getMember = async () => {
        await fetch(setting.api + "/api/getMember", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(a => {
                console.log(a);
                var res = a.data;
                var rr = [];
                res.map((rec) => {
                    rr.push({ value: rec.id, label: rec.fname + " " + rec.lname });
                });

                setList(rr);

            }
            );
    }

    const getFoodItem = async () => {
        await fetch(setting.api + "/api/getFoodItem", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(a => {
                console.log(a);
                setFoodList(a.data);

            }
            );
    }

    const getItemQuantity = async () => {
        //console.log(memberDiet);
        var list = [];
        member && foodList.map((a) => {
            var rec = memberDiet.filter((r) => r.food_item == a.id);
            // console.log(rec);
            list.push({
                "food_name": a.food_name,
                "qty": rec.length > 0 ? rec[0].qty : 0,
                "units": rec.length > 0 ? rec[0].units : '',
            })
            console.log(list);
            setDietList(list);
        })
    }


    useEffect(() => {

        getMember();
        getFoodItem();

    }, []);
    useEffect(() => {

        getItemQuantity();

    }, [memberDiet]);


    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Member Diet" />
                <div className="container-fluid py-4 main">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(saveDiet)}>
                                <Row>
                                    <Col sm={12} xs={12}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label className="text">Member Name</Form.Label>
                                            <Select options={list} onChange={(e) => getMemberById(e.value)} />
                                        </Form.Group>
                                        {member != null &&
                                            <div className="text-center mt-2 text">

                                                <div className="h6 mt-2 text">
                                                    <i className="ni business_briefcase-24 mr-2"></i>Name -{member && member.fname + ' ' + member.lname}
                                                    {/* {member != null ? member.fname : ''} */}
                                                </div>
                                                <div className="h6 mt-2 text">
                                                    <i className="ni business_briefcase-24 mr-2"></i>Email - {member && member.email}
                                                </div>
                                                <div className="h6 mt-2 text">
                                                    <i className="ni business_briefcase-24 mr-2"></i>Phone No. - {member && member.phone}
                                                </div>
                                            </div>
                                        }
                                        <Row>
                                            <Col sm={6} xs={6}>
                                                <Form.Check
                                                    label="Veg"
                                                //className="check"
                                                />
                                            </Col>

                                        </Row>
                                        <Card>
                                            <Card.Body>
                                                <Row>

                                                    <div className="table-responsive p-0">
                                                        <table className="table align-items-center mb-0">
                                                            <thead className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                <tr>
                                                                    <th >Item</th>
                                                                    <th >Qty</th>
                                                                    <th>Units</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody className="text-xs font-weight-bold mb-0">
                                                                {
                                                                    dietList && dietList.map((a, index) =>
                                                                        <tr key={index}>
                                                                            <td>
                                                                                {a.food_name}
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control type="text"
                                                                                    value={a.qty}
                                                                                    placeholder="Qty"
                                                                                    {...register("qty")}
                                                                                />
                                                                                {a.qty}
                                                                            </td>
                                                                            <td >
                                                                                <Form.Control type="text"
                                                                                    value={a.units}
                                                                                    placeholder="gm"
                                                                                    {...register("units")} />
                                                                                {a.units}
                                                                            </td>
                                                                        </tr>

                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Row>
                                            </Card.Body>
                                        </Card>



                                    </Col>
                                    <Col sm={6} xs={12}>


                                        <Button type="submit" className="btn bg-gradient-primary  pull-right btnsize" onClick={saveDiet} >Submit</Button>


                                    </Col>

                                </Row>

                            </Form>

                        </Card.Body>
                    </Card>



                </div>
                <Footer />
            </main>
        </>
    );
}

export default MemberDiet;