import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import setting from "../setting.json";
import { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";

const PlanAssign = () => {

    const [members, setMembers] = useState([]);
    const [plans, setPlans] = useState([]);
    const [memberDetail, setMemberDetail] = useState([]);
    const [planDetail, setPlanDetail] = useState([]);

    const renewalPlan = async (data) => {
        let su = {

            "plan_id": planDetail.id,
            "member_id": memberDetail.id,

        }
        await fetch(setting.api + "/api/renewalPlan",
            {
                body: JSON.stringify(su),
                method: "post",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(
                dd =>
                    console.log(dd)
            )
    }


    const getMember = async () => {
        await fetch(setting.api + "/api/getMember", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(a => {
                var res = a.data;
                var rr = [];
                res.map((rec) => {
                    rr.push({ value: rec.id, label: rec.fname + " " + rec.lname });
                });

                setMembers(rr);

            }
            );
    }

    const getMemberById = async (id) => {


        await fetch(setting.api + "/api/getMemberById?id=" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(
                response => response.json()
            )
            .then(
                (data) => {
                    console.log(data);
                    setMemberDetail(data.data);

                }
            );
    }


    const getPlanDetails = async () => {
        await fetch(setting.api + "/api/getPlanDetails", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(a => {
                var plan = a.data;
                var rr = [];
                plan.map((rec) => {
                    rr.push({ value: rec.id, label: rec.plan_name });
                });

                setPlans(rr);

            }
            );
    }
    const getPlanDetailsById = async (id) => {


        await fetch(setting.api + "/api/getPlanDetailsById?id=" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(
                response => response.json()
            )
            .then(
                (data) => {
                    console.log(data);
                    setPlanDetail(data.data);

                }
            );
    }


    useEffect(() => {
        getMember();

        getPlanDetails();
    }, []);


    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Plan Assign" />
                <div className="container-fluid py-4 main">
                    <Card>
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label className="text">Member Name</Form.Label>
                                            <Select options={members} id="member" onChange={(e) => getMemberById(e.value)} />
                                        </Form.Group>
                                        <div className=" box1  mt-2 text">


                                            <div className="mt-4 text">

                                                <div className="h6 mt-4 text">
                                                    <i className="ni business_briefcase-24 mr-2"></i>Name - {memberDetail && memberDetail.fname}{' '}
                                                    {memberDetail && memberDetail.lname}
                                                </div>
                                                <div className="h6 mt-2 text">
                                                    <i className="ni business_briefcase-24 mr-2"></i>Email - {memberDetail && memberDetail.email}
                                                </div>
                                                <div className="h6 mt-2 text">
                                                    <i className="ni business_briefcase-24 mr-2"></i>Phone No. - {memberDetail && memberDetail.phone}
                                                </div>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label className="text">Plan Name</Form.Label>
                                            <Select options={plans} onChange={(e) => getPlanDetailsById(e.value)} id="plan" />
                                        </Form.Group>
                                        <div className="box1 mt-4 ">

                                            <div className="h6 mt-4 text">
                                                <i className="ni business_briefcase-24 mr-2"></i>Plan Name - {planDetail.plan_name}
                                            </div>
                                            <div className="h6 mt-2 text">
                                                <i className="ni business_briefcase-24 mr-2"></i>Price - {planDetail.price}
                                            </div>

                                            <div className="h6 mt-2 text">
                                                <i className="ni business_briefcase-24 mr-2"></i>Duration - {planDetail.duration}
                                            </div>

                                        </div>
                                        <Button className="btn bg-gradient-primary  pull-right btnsize" onClick={renewalPlan}>Submit</Button>


                                    </Col>

                                </Row>

                            </Form>

                        </Card.Body>
                    </Card>



                </div>
                <Footer />
            </main>
        </>
    );
}

export default PlanAssign;