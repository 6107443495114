import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import setting from "../setting.json";
import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Form from 'react-bootstrap/Form';
import { Card, Col, Row, Button } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
    first_name: yup.string().required('Enter youer first name'),
    last_name: yup.string().required('Enter youer last name'),
    father_fname: yup.string().required('Enter youer father name'),
    father_lname: yup.string().required('Enter youer father name'),
    phone: yup.number().required('Enter youer Phone Number'),
    email: yup.string().email().required("Enter youer vallid email"),
    address: yup.string().required("Enter youer address"),
    country: yup.string().required("Enter youer country"),
    state: yup.string().required("Enter youer state"),
    city: yup.string().required("Enter youer city"),
    pincode: yup.number().required("Enter youer city"),

})

const UpdateMember = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })


    const getMemberDetailsById = async () => {
        await fetch(setting.api + "/api/getMemberDetailsById?id=" + id, {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(rec => {
                var gym = rec.data;
                reset({
                    "first_name": gym.fname,
                    "last_name": gym.lname,
                    "father_fname": gym.father_fname,
                    "father_lname": gym.father_lname,
                    "profession": gym.profession,
                    "email": gym.email,
                    "phone": gym.phone,
                    "address": gym.address,
                    "state": gym.state,
                    "city": gym.city,
                    "country": gym.country,
                    "pincode": gym.pincode,
                })
            }
            );

    }

    const Updatemember = async (gym) => {
        var um = {
            "id": 1,
            "fname": gym.first_name,
            "lname": gym.last_name,
            "father_fname": gym.father_fname,
            "father_lname": gym.father_lname,
            "profession": gym.profession,
            "email": gym.email,
            "phone": gym.phone,
            "address": gym.address,
            "state": gym.state,
            "city": gym.city,
            "country": gym.country,
            "pincode": gym.pincode,

        }
        await fetch(setting.api + "/api/updateMemberDetail",
            {
                body: JSON.stringify(um),
                method: "post",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(data => {
                getMemberDetailsById();
                reset(formValues => ({
                    ...formValues,


                }))
            }

            );
        navigate('/memberList');
    }
    const getCountryMaster = async () => {
        await fetch(setting.api + "/api/getCountryMaster", {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(sd => {
                setCountryList(sd.data);

            }
            );

    }
    const getStateByCountryId = async (e) => {
        var id = e.target.value;
        console.log(id);
        await fetch(setting.api + "/api/getStateByCountryId?id=" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(sm => {
                setStateList(sm.data);
            });
    }

    const getCityByStateId = async (e) => {
        var id = e.target.value;
        await fetch(setting.api + "/api/getCityByStateId?id=" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(cm => {
                setCityList(cm.data);
            });
    }
    useEffect(() => {
        getMemberDetailsById();
        getCountryMaster();
    }, []);

    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Update Member" />
                <div className="container-fluid py-4 main">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(Updatemember)}>
                                <Row>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>First First Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter First Name" id='first_name' {...register("first_name")} />
                                            {errors.first_name && <p>{errors.first_name.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Last Name" id='last_name'
                                                {...register("last_name")}
                                            />
                                            {errors.last_name && <p>{errors.last_name.message}</p>}
                                        </Form.Group>


                                        <Form.Group className="mb-3" >
                                            <Form.Label>Father First Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter First Name" id='father_fname' {...register("father_fname")} />
                                            {errors.father_fname && <p>{errors.father_fname.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Father Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Last Name" id='father_lname'
                                                {...register("father_lname")}
                                            />
                                            {errors.father_lname && <p>{errors.father_lname.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Profession</Form.Label>
                                            <Form.Control type="text" placeholder="Enter First Name" id='profession' {...register("profession")} />
                                            {errors.profession && <p>{errors.profession.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Phone number" {...register("phone")} />
                                            {errors.phone && <p>{errors.phone.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col sm={6} xs={12}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Email </Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email"
                                                id='email' {...register("email")}
                                            />
                                            {errors.email && <p>{errors.email.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Address </Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address"
                                                id='addess' {...register("address")}
                                            />
                                            {errors.address && <p>{errors.address.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Country</Form.Label>
                                            <Form.Select aria-label="Default select example"
                                                {...register('country')} onChange={(e) => getStateByCountryId(e)} >
                                                {
                                                    countryList && countryList.map((sd) =>
                                                        <option key={sd.id} value={sd.id} >{sd.name}</option>
                                                    )
                                                }

                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>State</Form.Label>
                                            <Form.Select aria-label="Default select example"
                                                id='state' {...register('state')}
                                                onChange={(e) => getCityByStateId(e)}
                                            >
                                                {
                                                    stateList && stateList.map((sm) =>
                                                        <option key={sm.id} value={sm.id} >{sm.state_name}</option>

                                                    )
                                                }


                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>City</Form.Label>
                                            <Form.Select aria-label="Default select example" {...register('city')}>
                                                {
                                                    cityList && cityList.map((cm) =>
                                                        <option key={cm.id} value={cm.id}>{cm.city_name}</option>
                                                    )
                                                }
                                                <option>--Select--</option>
                                                <option value="1">lucknow</option>
                                                <option value="2">sultanpur</option>

                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Pincode </Form.Label>
                                            <Form.Control type="number" placeholder="Enter pincode" id='pincode' {...register('pincode')} />
                                            {errors.pincode && <p>{errors.pincode.message}</p>}
                                        </Form.Group>

                                        <Button type='submit' className="btn bg-gradient-primary  pull-right btnsize">Update</Button>
                                    </Col>

                                </Row>

                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <Footer />
            </main>
        </>
    );
}

export default UpdateMember;