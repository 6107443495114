import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { Card, Col, Row, Button } from "react-bootstrap";
import { useEffect } from "react";
import setting from "../setting.json";
import { useNavigate } from "react-router-dom";
const schema = yup.object().shape({
    fname: yup.string().required('Enter youer first name'),
    lname: yup.string().required('Enter youer last name'),
    email: yup.string().required('Enter youer email name'),
    phone: yup.string().required('Enter youer phone name'),

})

const UpdateUsers = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })

    const getUsersDetailsById = async () => {
        await fetch(setting.api + "/api/getUsersDetailsById?id=" + id, {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(gym => {
                var res = gym.data
                reset({
                    "fname": res.fname,
                    "lname": res.lname,
                    "email": res.email,
                    "phone": res.phone,

                })
            }

            );

    }

    const UpdateUsers = async (gym) => {
        var ud = {
            "id": id,
            "fname": gym.fname,
            "lname": gym.lname,
            "email": gym.email,
            "phone": gym.phone,

        }
        await fetch(setting.api + "/api/updateUserDetails",
            {
                body: JSON.stringify(ud),
                method: "post",
                // mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(data => {
                getUsersDetailsById();
                reset(formValues => ({
                    ...formValues,
                    "fname": '',
                    "lname": '',
                    "email": '',
                    "phone": '',

                }))
            }

            );
        navigate('/userlist');
    }

    useEffect(() => {
        getUsersDetailsById();
    }, []);

    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Update Users" />
                <div className="container-fluid py-4 main">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(UpdateUsers)}>
                                <Row>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter First Name" {...register("fname")} />
                                            {errors.fname && <p>{errors.fname.message}</p>}

                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Last Name" {...register("lname")} />
                                            {errors.lname && <p>{errors.lname.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Phone number" {...register("phone")} />
                                            {errors.phone && <p>{errors.phone.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Email </Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email"
                                                {...register("email")} />
                                            {errors.email && <p>{errors.email.message}</p>}
                                        </Form.Group>

                                        <Button type="submit" className="btn bg-gradient-primary  pull-right btnsize">Update</Button>
                                    </Col>

                                </Row>

                            </Form>
                        </Card.Body>
                    </Card>



                </div>
                <Footer />
            </main>
        </>
    );
}

export default UpdateUsers;