import { Link, redirect } from "react-router-dom";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LogoutIcon from '@mui/icons-material/Logout';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from "react-router-dom";
const Header = ({ title }) => {
    const navigate = useNavigate();
    const toggleNav = () => {
        // Toggle Sidenav
        const iconNavbarSidenav = document.getElementById('iconNavbarSidenav');
        const iconSidenav = document.getElementById('iconSidenav');
        const sidenav = document.getElementById('sidenav-main');
        let body = document.getElementsByTagName('body')[0];
        let className = 'g-sidenav-pinned';

        if (iconNavbarSidenav) {
            iconNavbarSidenav.addEventListener("click", toggleSidenav);
        }

        if (iconSidenav) {
            iconSidenav.addEventListener("click", toggleSidenav);
        }

        function toggleSidenav() {
            if (body.classList.contains(className)) {
                body.classList.remove(className);
                setTimeout(function () {
                    sidenav.classList.remove('bg-white');
                }, 100);
                sidenav.classList.remove('bg-transparent');

            } else {
                body.classList.add(className);
                sidenav.classList.add('bg-white');
                sidenav.classList.remove('bg-transparent');
                iconSidenav.classList.remove('d-none');
            }
        }

        let html = document.getElementsByTagName('html')[0];

        html.addEventListener("click", function (e) {
            if (body.classList.contains('g-sidenav-pinned') && !e.target.classList.contains('sidenav-toggler-line')) {
                body.classList.remove(className);
            }
        });
    }
    const Logout = () => {
        localStorage.clear();
        navigate('/');
    }

    return (
        <>
            <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl " id="navbarBlur" data-scroll="false">
                <div className="container-fluid py-1 px-3">
                    <nav aria-label="breadcrumb">

                        <h6 className="font-weight-bolder text-white mb-0">{title}</h6>
                    </nav>
                    <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">

                        <ul className="navbar-nav  justify-content-end">

                            <li class="nav-item dropdown pe-2 d-flex align-items-center">
                                <Link to="#" className="nav-link text-white font-weight-bold p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-user me-sm-1"></i>
                                    <span className="d-sm-inline "> {JSON.parse(localStorage.getItem("loggedUser")).fname}</span>
                                </Link>



                                <ul class="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                                    <li class="mb-2">
                                        <Link class="dropdown-item border-radius-md" to="/profile">
                                            <div class="d-flex py-1">
                                                <div class="my-auto">
                                                    <AssignmentIndIcon />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="text-sm font-weight-normal mb-1">
                                                        <span class="font-weight-bold">Profile</span>
                                                    </h6>

                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li class="mb-2">
                                        <Link class="dropdown-item border-radius-md" to="/changePassword">
                                            <div class="d-flex py-1">
                                                <div class="my-auto">
                                                    <EditNoteIcon />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="text-sm font-weight-normal mb-1">
                                                        <span class="font-weight-bold">Change Password</span>
                                                    </h6>

                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li class="mb-2">
                                        <Link class="dropdown-item border-radius-md" to="#">
                                            <div class="d-flex py-1">
                                                <div class="my-auto">
                                                    <LogoutIcon />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="text-sm font-weight-normal mb-1">
                                                        <span class="font-weight-bold" onClick={Logout}>Logout</span>
                                                    </h6>

                                                </div>
                                            </div>
                                        </Link>
                                    </li>

                                </ul>
                            </li>
                            <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                                <Link to="#" className="nav-link text-white p-0" id="iconNavbarSidenav" onClick={toggleNav}>
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line bg-white"></i>
                                        <i className="sidenav-toggler-line bg-white"></i>
                                        <i className="sidenav-toggler-line bg-white"></i>
                                    </div>
                                </Link>
                            </li>


                        </ul>
                    </div>
                </div>
            </nav>

        </>
    );
}

export default Header;