import { Link } from "react-router-dom";
import React from "react";
import { useEffect, useState } from "react";
import setting from "../setting.json";
const Sidebar = () => {
    const [list, setList] = useState([]);

    const getRole = async () => {
        await fetch(setting.api + "/api/getRole", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(a => {
                console.log(a);
                setList(a.data);

            }
            );

    }
    useEffect(() => {
        getRole();
    }, []);
    return (
        <>
            <aside className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 aside" id="sidenav-main">
                <div className="sidenav-header">
                    <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
                    <Link className="navbar-brand m-0" to="/" >
                        <img src="../assets/img/logo-ct-dark.png" className="navbar-brand-img h-100" alt="main_logo" />
                        <span className="ms-1 font-weight-bold">Dashboard </span>
                    </Link>
                </div>
                <hr className="horizontal dark mt-0" />
                <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                    {list.role == 1 ?
                        <ul className="navbar-nav">

                            <li className="nav-item">
                                <Link className="nav-link active" to="/">
                                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                        <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Dashboard</span>
                                </Link>
                            </li>


                            <li className="nav-item">
                                <Link className="nav-link " to="/userlist">
                                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                        <i className="ni ni-calendar-grid-58 text-warning text-sm opacity-10"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">User</span>
                                </Link>
                            </li>


                            <li className="nav-item">
                                <Link className="nav-link " to="/planList">
                                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                        <i className="ni ni-calendar-grid-58 text-warning text-sm opacity-10"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Plan</span>
                                </Link>
                            </li>


                        </ul>
                        : ''
                    }
                    {list.role == 2 ?
                        <ul className="navbar-nav">

                            <li className="nav-item">
                                <Link className="nav-link active" to="/">
                                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                        <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Dashboard</span>
                                </Link>
                            </li>






                            <li className="nav-item">
                                <Link className="nav-link " to="/memberList">
                                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                        <i className="ni ni-calendar-grid-58 text-warning text-sm opacity-10"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Member</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link " to="/memberDiet">
                                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                        <i className="ni ni-single-02 text-dark text-sm opacity-10"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Member Diet</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link " to="/healthList">
                                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                        <i className="ni ni-single-02 text-dark text-sm opacity-10"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Health List</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link " to="/planAssign">
                                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                        <i className="ni ni-single-02 text-dark text-sm opacity-10"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Plan Assign</span>
                                </Link>
                            </li>



                        </ul>
                        : ''
                    }
                </div>

            </aside>
        </>
    );
}

export default Sidebar;