import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import setting from "../setting.json";

const HealthList = () => {

    const [list, setList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const getMember = async () => {
        await fetch(setting.api + "/api/getMember", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(u => {
                setList(u.data);
                setSearchList(u.data);

            }
            );
        // console.log(list);
    }

    useEffect(() => {
        getMember();
    }, []);

    const searchMember = async (e) => {
        var text = e.target.value;
        console.log(text);
        const dd = list.filter((u) => (u.fname).search(text) > -1);
        console.log(dd);
        setSearchList(dd);

    }
    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Health List" />
                <div className="container-fluid py-4">

                    <div className="row">
                        <div className="col-12">

                            <div className="card mb-4">
                                <div className="card-header ">
                                    <Link to="/memberhealth">
                                        <span className="badge badge-sm bg-gradient-primary add">+Add Member Health</span>
                                    </Link>

                                </div>

                                <Row>
                                    <Col sm={12} xs={12}>
                                        <div className="ms-md-auto ml-4 d-flex align-items-center search ser"><div className="input-group"><span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                                            <input type="text" className="form-control" placeholder="Enter Name" autocomplete="off" onChange={(e) => searchMember(e)} />
                                        </div></div>
                                    </Col>

                                </Row>
                                <div className="card-body p-3 pb-0">
                                    <ul className="list-group">
                                        {
                                            searchList && searchList.map((h, i) =>
                                                <li className="list-group-item border-1 d-flex justify-content-between ps-1 mb-2  border-radius-lg " key={h.i}>
                                                    <div className="d-flex flex-column box">
                                                        <h6 className="mb-1 text-dark font-weight-bold text-lg">   {h.fname + ' ' + h.lname}</h6>
                                                        <span className="font-weight-bold text-sm"> {h.phone} <br />  </span>
                                                    </div>
                                                    <div className="d-flex align-items-center text-sm  ">


                                                        <Link to={`/updateMemberHealth/${h.id}`}>
                                                            <span className="badge badge-sm badge badge-sm bg-dark">
                                                                <i className="fas fa-pencil-alt">
                                                                </i>Edit</span>
                                                        </Link>
                                                    </div>
                                                </li>
                                            )}


                                    </ul>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    );
}

export default HealthList;