import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Button, Image } from "react-bootstrap";
import setting from "../setting.json";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { Link } from "react-router-dom";

const schema = yup.object().shape({
    email: yup.string().email().required("Enter youer vallid email"),
    password: yup.string()
        .required('No password provided.')
        .min(1, 'Password is too short - should be 1 chars minimum.')
    // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),

})

const SignIn = () => {

    const navigate = useNavigate();
    const [msg, setMsg] = useState('');
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })

    const login = (gym) => {
        reset();
        let lg = {
            "email": gym.email,
            "password": gym.password,

        }

        fetch(setting.api + "/api/userlogin",
            {
                body: JSON.stringify(lg),
                method: "post",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },

            })
            .then(
                response => response.json()
            )
            .then(
                (dd) => {
                    console.log(dd);
                    if (dd.status == "success") {
                        var user = {
                            /* "userId": dd.data.id,
                            "fname": dd.data.fname,
                            "lname": dd.data.lname,
                            "email": dd.data.email,
                            "role": dd.data.role, */
                            "fname": dd.data.fname,
                            "lname": dd.data.lname,
                            "token": dd.data.token_no,

                        }
                        localStorage.setItem("loggedUser", JSON.stringify(user))
                        navigate('/dashboard');
                    }
                    else {
                        setMsg(dd.reason);
                    }

                }
            )
    }

    return (
        <>

            <main className="main-content  mt-0">
                <section>

                    <div className="page-header min-vh-100">

                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                                    <div className="card card-plain">

                                        <div className="card-body">
                                            <Image src="./assets/img/logos/Daily.webp" width={70} height={70} className='logo' />
                                            <h2 className='gym'>The Beast Factory</h2>
                                            <h4 className="font-weight-bolder">Sign In</h4>
                                            <Form onSubmit={handleSubmit(login)}>
                                                <p className='msg'>{msg}</p>
                                                <div className="mb-3">
                                                    <input type="email" className="form-control form-control-lg" placeholder="Email" aria-label="Email" {...register("email")} />

                                                    {errors.email && <p className='msg'>{errors.email.message}</p>}
                                                </div>
                                                <div className="mb-3">
                                                    <input type="password" className="form-control form-control-lg" placeholder="Password" aria-label="Password" {...register("password")} />
                                                    {errors.password && <p className='msg'>{errors.password.message}</p>}
                                                </div>

                                                <div className="text-center">
                                                    <Button type="submit" className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">Sign in</Button>
                                                </div>
                                            </Form>

                                            <Link to="/email">
                                                <p>Forget Password</p>
                                            </Link>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                                    <div className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden signimg" >
                                        <span className="mask bg-gradient-primary opacity-6"></span>
                                        <h4 className="mt-5 text-white font-weight-bolder position-relative">"Attention is the new currency"</h4>
                                        <p className="text-white position-relative">The more effortless the writing looks, the more effort the writer actually put into the process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default SignIn;