import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";
import Form from 'react-bootstrap/Form';
import { Card, Col, Row, Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import React from "react";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import setting from "../setting.json";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
    plan: yup.string().required('Enter youer plan name'),
    duration: yup.string().required('Enter youer duration'),
    price: yup.number().required('Enter youer price'),
    free_days: yup.string().required('Enter youer Demo_days'),

})

const UpdatePlan = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })

    const getPlanDetailsById = async () => {
        await fetch(setting.api + "/api/getPlanDetailsById?id=" + id, {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(res => {
                var gym = res.data;
                reset({

                    "plan": gym.plan_name,
                    "duration": gym.duration,
                    "price": gym.price,
                    "free_days": gym.free_days,

                })
            }
            );

    }

    const updatePlan = async (gym) => {
        var up = {
            "id": id,
            "plan_name": gym.plan,
            "duration": gym.duration,
            "price": gym.price,
            "free_days": gym.free_days,

        }
        await fetch(setting.api + "/api/updatePlanDetail",
            {
                body: JSON.stringify(up),
                method: "post",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(data => {
                getPlanDetailsById();
                reset(formValues => ({
                    ...formValues,

                }))
            }

            );
        navigate('/planList');
    }

    useEffect(() => {
        getPlanDetailsById();

    }, []);

    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Update Plan" />
                <div className="container-fluid py-4 main">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(updatePlan)}>
                                <Row>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Plan Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Plan Name" {...register("plan")} />
                                            {errors.plan && <p>{errors.plan.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Duration</Form.Label>
                                            <Form.Select aria-label="Default select example"
                                                {...register("duration")}>
                                                <option>--Select--</option>
                                                <option value="1">Monthly</option>
                                                <option value="2">Weekly</option>
                                                <option value="3">Days</option>
                                            </Form.Select>
                                        </Form.Group>

                                    </Col>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Price" {...register("price")} />
                                            {errors.price && <p>{errors.price.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Free Days </Form.Label>
                                            <Form.Select aria-label="Default select example"
                                                {...register("free_days")}>
                                                <option>--Select--</option>
                                                <option value="1">Monthly</option>
                                                <option value="2">Weekly</option>
                                                <option value="3">Days</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Button type="submit" className="btn bg-gradient-primary  pull-right btnsize" >Update</Button>

                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <Footer />
            </main>
        </>
    );
}

export default UpdatePlan;