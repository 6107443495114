import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { useEffect } from 'react';
import setting from '../setting.json';
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
    fname: yup.string().required('Enter your First name'),
    lname: yup.string().required('Enter your Last name'),
    phone: yup.number().required('Enter your Phone'),
})
const EditProfile = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState([]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })
    const getUserProfileById = async () => {

        await fetch(setting.api + "/api/getUserProfile", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            }
        })
            .then(response => response.json())
            .then(res => {
                var gym = res.data;
                reset({
                    "fname": gym.fname,
                    "lname": gym.lname,
                    "phone": gym.phone,


                }
                )
            }
            );

    }
    const updateProfile = async (data) => {
        var rec = {
            "fname": data.fname,
            "lname": data.lname,
            "phone": data.phone
        }
        await fetch(setting.api + "/api/updateProfile", {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(rec),
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            }
        })
            .then(response => response.json())
            .then(res => {
                navigate('/profile');

            }
            );
    }

    useEffect(() => {
        getUserProfileById();
    }, []);
    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Edit Profile" />


                <div className="container-fluid py-4">

                    <div className="row">
                        <div className="col-md-8">
                            <Card>
                                <Card.Body>
                                    <Form onSubmit={handleSubmit(updateProfile)}>


                                        <Row>
                                            <Col sm={6} xs={12}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>First name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter First Name"  {...register("fname")} />
                                                    {errors.fname && <p>{errors.fname.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} xs={12}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Last name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Last Name"
                                                        {...register("lname")} />
                                                </Form.Group>
                                            </Col>

                                            <Col sm={6} xs={12}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Phone </Form.Label>
                                                    <Form.Control type="Number" placeholder="Number"
                                                        {...register("phone")} />

                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Button type="submit" className="btn bg-gradient-primary  pull-right btnsize">Update</Button>
                                    </Form>


                                </Card.Body>
                            </Card>
                        </div>

                    </div>

                </div>

                <Footer />
            </main>
        </>
    );
}

export default EditProfile;