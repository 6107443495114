import { Form, Button, Image } from "react-bootstrap";
import setting from '../setting.json';
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
    email: yup
        .string()
        .required('Email is required'),

})

const Email = () => {
    const navigate = useNavigate();
    const [msg, setMsg] = useState('');
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })
    const SentOTP = async (data) => {
        var rec = {
            "email": data.email,



        }
        await fetch(setting.api + "/api/SentOTP", {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(rec),
            headers: {
                "Content-Type": "application/json",

            }
        })
            .then(response => response.json())
            .then(res => {
                console.log(res);
                localStorage.setItem("tempuser", data.email)
                if (res.result == "success") {
                    navigate('/forgetPassword')

                } else {
                    setMsg(res.reason);
                }

            }
            );
    }

    return (
        <>
            <main className="main-content  mt-0">
                <section>

                    <div className="page-header min-vh-100">

                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                                    <div className="card card-plain">

                                        <div className="card-body">
                                            <Image src="./assets/img/logos/Daily.webp" width={70} height={70} className='logo' />
                                            <h2 className='gym'>Beast Gym</h2>
                                            <h4 className="font-weight-bolder">Forget Password</h4>
                                            <Form onSubmit={handleSubmit(SentOTP)}>
                                                <p className='msg'>{msg}</p>
                                                <div className="mb-3">
                                                    <input type="email" className="form-control form-control-lg" placeholder="Email" aria-label="Email"{...register("email")} />
                                                    {errors.email && <p>{errors.email.message}</p>}


                                                </div>


                                                <div className="text-center">
                                                    <Button type="submit" className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">Send </Button>
                                                </div>
                                            </Form>



                                        </div>

                                    </div>
                                </div>
                                <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                                    <div className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden signimg" >
                                        <span className="mask bg-gradient-primary opacity-6"></span>
                                        <h4 className="mt-5 text-white font-weight-bolder position-relative">"Attention is the new currency"</h4>
                                        <p className="text-white position-relative">The more effortless the writing looks, the more effort the writer actually put into the process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Email;