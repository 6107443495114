import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import setting from '../setting.json';
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from 'react';

const schema = yup.object().shape({
    oldpassword: yup
        .string()
        .required('Old Password is required'),
    newpassword: yup
        .string()
        .required('New Password is required '),
    cpassword: yup
        .string()
        .required('Confirm Password is required')
        .oneOf([yup.ref("newpassword")], "Passwords do not match"),
})
const ChangePassword = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })
    const forgetPassword = async (data) => {
        var rec = {
            "oldpassword": data.oldpassword,
            "newpassword": data.newpassword,
            "cpassword": data.cpassword,


        }
        await fetch(setting.api + "/api/ForgetPassword", {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(rec),
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            }
        })
            .then(response => response.json())
            .then(res => {
                reset({
                    "oldpassword": '',
                    "newpassword": '',
                    "cpassword": ''
                })

            }
            );
    }
    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Change Password" />


                <div className="container-fluid py-4">

                    <div className="row">
                        <div className="col-md-8">
                            <div className="card">

                                <div className="card-body">
                                    <Form onSubmit={handleSubmit(forgetPassword)}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="example-text-input" className="form-control-label">Old Password</label>
                                                    <input className="form-control" type="password" {...register("oldpassword")} />
                                                    {errors.oldpassword && <p>{errors.oldpassword.message}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="example-text-input" className="form-control-label">New Password</label>
                                                    <input className="form-control" type="password" {...register("newpassword")} />
                                                    {errors.newpassword && <p>{errors.newpassword.message}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="example-text-input" className="form-control-label">Confirm Password </label>
                                                    <input className="form-control" type="password" {...register("cpassword")} />
                                                    {errors.cpassword && <p>{errors.cpassword.message}</p>}
                                                </div>
                                            </div>


                                        </div>



                                        <Button type="submit" className="btn bg-gradient-primary  pull-right btnsize">Update</Button>
                                    </Form>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <Footer />
            </main >
        </>
    );
}

export default ChangePassword;