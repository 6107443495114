import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from "react-router-dom";
import { Card, Col, Row, Button, Form, Image } from "react-bootstrap";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useEffect } from 'react';
import setting from '../setting.json';
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const Profile = () => {
    const [userInfo, setUserInfo] = useState([]);


    const getUserProfileById = async () => {

        await fetch(setting.api + "/api/getUserProfile", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            }
        })
            .then(response => response.json())
            .then(res => {
                var gym = res.data;
                setUserInfo({
                    "name": gym.fname + ' ' + gym.lname,
                    "email": gym.email,
                    "phone": gym.phone,
                    "image": gym.image,

                }
                )
            }
            );

    }

    useEffect(() => {
        getUserProfileById();
    }, []);
    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Profile" />
                <div className="container-fluid py-4">

                    <Row>
                        <Col sm={6} xs={12}>

                            <Card>

                                < Image src="../assets/img/bg-profile.jpg" alt="Image placeholder" className="card-img-top" />

                                <Row className="justify-content-center">
                                    <Col sm={4} xs={12}>


                                        <Col sm={4} xs={12} className="mt-4 ">
                                            <Form>

                                                <Image src={setting.api + '/uploads/' + userInfo.image} className="rounded-circle img-fluid border border-2 border-white img8" />

                                                <Form.Group className="mb-3 img5" controlId="imgfile">
                                                    <Form.Label className="camera"><CameraAltIcon /></Form.Label>
                                                    <Form.Control type="file" className="file" />
                                                </Form.Group>

                                            </Form>
                                        </Col>


                                    </Col>
                                </Row>

                                <div className="card-body pt-0">

                                    <div className="text-center mt-2">
                                        < Link to="/ediprofile">
                                            <span className="badge badge-xs badge badge-xs bg-dark up">
                                                <i className="fas fa-pencil-alt">
                                                </i></span>
                                        </Link>
                                        <h5>
                                            {userInfo && userInfo.name}<span className="font-weight-light"></span>
                                        </h5>
                                        <div className="h6 font-weight-300">
                                            <i className="ni location_pin mr-2"></i>phone - {userInfo && userInfo.phone}
                                        </div>
                                        <div className="h6 font-weight-300">
                                            <i className="ni location_pin mr-2"></i>Email - {userInfo && userInfo.email}
                                        </div>


                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>

                <Footer />
            </main>
        </>
    );
}

export default Profile;