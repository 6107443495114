import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Form from 'react-bootstrap/Form';
import { Card, Col, Row, Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import setting from "../setting.json";
import { useNavigate } from "react-router-dom";
const schema = yup.object().shape({
    fname: yup.string().required('Enter youer first name'),
    lname: yup.string().required('Enter youer last name'),
    email: yup.string().required('Enter youer email name'),
    phone: yup.string().required('Enter youer phone name'),
    password: yup.string().required('Enter youer password'),

})

const Users = () => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })

    const saveUser = async (data) => {
        let su = {
            "fname": data.fname,
            "lname": data.lname,
            "email": data.email,
            "phone": data.phone,
            "password": data.password,

        }
        await fetch(setting.api + "/api/saveUserDetails",
            {
                body: JSON.stringify(su),
                method: "post",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(
                dd =>
                    console.log(dd)
            )
        navigate('/userlist');
    }

    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Add User" />
                <div className="container-fluid py-4 main">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(saveUser)}>
                                <Row>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter First Name" {...register("fname")} />
                                            {errors.fname && <p>{errors.fname.messege}</p>}
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Last Name" {...register("lname")} />
                                            {errors.lname && <p>{errors.lname.messege}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Phone number"   {...register("phone")} />
                                            {errors.phone && <p>{errors.phone.messege}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Email </Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email"
                                                {...register("email")} />
                                            {errors.email && <p>{errors.email.messege}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Enter password"   {...register("password")} />
                                            {errors.password && <p>{errors.password.messege}</p>}
                                        </Form.Group>

                                        <Button type="submit" className="btn bg-gradient-primary  pull-right btnsize">Submit</Button>
                                    </Col>

                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <Footer />
            </main>
        </>
    );
}

export default Users;