import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";
import Form from 'react-bootstrap/Form';
import React from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Card, Col, Row, Button } from "react-bootstrap";
import setting from "../setting.json";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
    member_name: yup.string().required('Enter youer member name'),
    chest: yup.number().required('Enter youer chest'),
    biceps: yup.number().required('Enter youer Father name'),
    shoulder: yup.number().required('Enter youer Father name'),
    waist: yup.number().required('Enter youer profession name'),
    thigh: yup.number().required('Enter youer Phone Number'),

})

const MemberHealth = () => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })
    const [memberList, setMemberList] = useState([]);
    const saveMember = async (data) => {
        let sm = {

            "member_id": data.member_name,
            "chest": data.chest,
            "biceps": data.biceps,
            "shoulder": data.shoulder,
            "waist": data.waist,
            "thigh": data.thigh,

        }
        await fetch(setting.api + "/api/saveMemberHealth",
            {
                body: JSON.stringify(sm),
                method: "post",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(dd =>
                console.log(dd)
            )
        navigate('/healthList');
    }
    const getMemberDetails = async () => {
        await fetch(setting.api + "/api/getMemberDetails", {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(sd => {
                setMemberList(sd.data);

            }
            );

    }
    useEffect(() => {
        getMemberDetails();

    }, []);

    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Member Health" />
                <div className="container-fluid py-4 main">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(saveMember)}>
                                <Row>
                                    <Col sm={6} xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Member Name</Form.Label>
                                            <Form.Select aria-label="Default select example"
                                                {...register("member_name")}
                                            >
                                                {
                                                    memberList && memberList.map((sd) =>
                                                        <option key={sd.id} value={sd.id} >{sd.fname + ' ' + sd.lname}</option>
                                                    )
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Chest</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Chest" {...register("chest")} />
                                            {errors.chest && <p>{errors.chest.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Shoulder</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Shoulder" {...register("shoulder")} />
                                            {errors.shoulder && <p>{errors.shoulder.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Biceps</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Biceps" {...register("biceps")} />
                                            {errors.biceps && <p>{errors.biceps.message}</p>}
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Waist</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Waist" {...register("waist")} />
                                            {errors.waist && <p>{errors.waist.message}</p>}
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Thigh</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Thigh" {...register("thigh")} />
                                            {errors.thigh && <p>{errors.thigh.message}</p>}
                                        </Form.Group>
                                        <Button type="submit" className="btn bg-gradient-primary  pull-right btnsize" >Submit</Button>


                                    </Col>

                                </Row>

                            </Form>

                        </Card.Body>
                    </Card>



                </div>
                <Footer />
            </main>
        </>
    );
}

export default MemberHealth;