import logo from './logo.svg';
import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Dashboard from './Pages/DashBoard';
import UserList from './Pages/UserList';

import SignIn from './Pages/SignIn';
import Users from './Pages/Users';
import UpdateUsers from './Pages/UpdateUser';
import MemberList from './Pages/MemberList';
import AddMember from './Pages/MemberRegistration';
import UpdateMember from './Pages/UpdateMember';
import Plan from './Pages/Plan';
import PlanList from './Pages/PlanList';
import UpdatePlan from './Pages/UpdatePlan';
import MemberHealth from './Pages/MemberHealth';
import MemberDiet from './Pages/MemberDiet';
import HealthList from './Pages/HealthList';
import UpdateMemberHealth from './Pages/UpdateMemberHealth';
import PlanAssign from './Pages/PlanAssign';
import Profile from './Pages/Profile';
import EditProfile from './Pages/EditProfile';
import ChangePassword from './Pages/ChangPassword';
import Email from './Pages/OTPEmail';
import ForgetPassword from './Pages/ForgetPassword';
import React from "react";
import { useEffect, useState } from "react";
import setting from './setting.json';

const admin = [
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/userlist",
    element: <UserList />,
  },
  {
    path: "/user",
    element: <Users />,
  },
  {
    path: "/updateUser/:id",
    element: <UpdateUsers />,
  },

  {
    path: "/plan",
    element: <Plan />,
  },
  {
    path: "/planList",
    element: <PlanList />,
  },
  {
    path: "/updatePlan/:id",
    element: <UpdatePlan />,
  },

  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/ediprofile",
    element: <EditProfile />,
  },
  {
    path: "/changePassword",
    element: <ChangePassword />,
  },
  {
    path: "/email",
    element: <Email />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
];

const operator = [
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },

  {
    path: "/memberList",
    element: <MemberList />,
  },
  {
    path: "/addmember",
    element: <AddMember />,
  },
  {
    path: "/updateMember/:id",
    element: <UpdateMember />,
  },

  {
    path: "/memberHealth",
    element: <MemberHealth />,
  },
  {
    path: "/planAssign",
    element: <PlanAssign />,
  },
  {
    path: "/memberDiet",
    element: <MemberDiet />,
  },
  {
    path: "/healthList",
    element: <HealthList />,
  },
  {
    path: "/updateMemberHealth/:id",
    element: <UpdateMemberHealth />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/ediprofile",
    element: <EditProfile />,
  },
  {
    path: "/changePassword",
    element: <ChangePassword />,
  },
  {
    path: "/email",
    element: <Email />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
];
const router1 = createBrowserRouter(admin);
const router2 = createBrowserRouter(operator);
function App() {
  const [list, setList] = useState([]);


  const getRole = async () => {
    await fetch(setting.api + "/api/getRole", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
      },
    })
      .then(response => response.json())
      .then(a => {
        //console.log(a);
        setList(a.data);

      }
      );

  }
  useEffect(() => {
    getRole();
  }, []);
  return (
    <div className="App">
      {/*  {list.role} */}
      {list.role == 1 ?
        <RouterProvider router={router1} />
        :
        <RouterProvider router={router2} />
      }
    </div>
  );
}

export default App;
