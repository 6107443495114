import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";
import Form from 'react-bootstrap/Form';
import { Card, Col, Row, Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import setting from "../setting.json";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
    plan: yup.string().required('Enter youer first name'),
    duration: yup.string().required('Enter youer last name'),
    price: yup.number().required('Enter youer price'),
    free_days: yup.string().required('Enter youer Demo_days'),

})
const Plan = () => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })

    const savePlans = async (data) => {
        let su = {
            "plan_name": data.plan,
            "duration": data.duration,
            "price": data.price,
            "free_days": data.free_days,

        }
        await fetch(setting.api + "/api/addPlanDetail",
            {
                body: JSON.stringify(su),
                method: "post",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(
                dd =>
                    console.log(dd)
            )
        navigate('/planList');

    }

    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Plan" />
                <div className="container-fluid py-4 main">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(savePlans)}>
                                <Row>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Plan Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Plan Name" {...register("plan")} />
                                            {errors.plan && <p>{errors.plan.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Duration</Form.Label>
                                            <Form.Select aria-label="Default select example"
                                                {...register("duration")}>
                                                <option>--Select--</option>
                                                <option value="1">Monthly</option>
                                                <option value="2">Weekly</option>
                                                <option value="3">Days</option>
                                            </Form.Select>
                                        </Form.Group>

                                    </Col>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Price"  {...register("price")} />
                                            {errors.price && <p>{errors.price.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Free Days </Form.Label>
                                            <Form.Select aria-label="Default select example"
                                                {...register("free_days")}>
                                                <option>--Select--</option>
                                                <option value="1">Monthly</option>
                                                <option value="2">Weekly</option>
                                                <option value="3">Days</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Button type="submit" className="btn bg-gradient-primary  pull-right btnsize" >Submit</Button>

                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>



                </div>
                <Footer />
            </main>
        </>
    );
}

export default Plan;