import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import React, { useState } from "react";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import setting from "../setting.json";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
    member_id: yup.string().required('Enter youer member name'),
    chest: yup.number().required('Enter youer chest'),
    biceps: yup.number().required('Enter youer Father name'),
    shoulder: yup.number().required('Enter youer Father name'),
    waist: yup.number().required('Enter youer profession name'),
    thigh: yup.number().required('Enter youer Phone Number'),

})

const UpdateHealth = () => {
    const navigate = useNavigate();
    const [member, setMember] = useState([]);
    const [memberInfo, setMemberInfo] = useState([]);

    const { id } = useParams();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })
    

    const getMemberHealthByMemberId = async () => {
        await fetch(setting.api + "/api/getMemberHealthByMemberId?id=" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(res => {
                var gym = res.data;
                setMemberInfo({
                    "name": gym.fname + ' ' + gym.lname,
                    "email": gym.email,
                    "phone": gym.phone,

                }
                )
                reset({
                    "member_id": gym.member_id,
                    "chest": gym.chest,
                    "biceps": gym.biceps,
                    "shoulder": gym.shoulder,
                    "waist": gym.waist,
                    "thigh": gym.thigh,
                })
            }
            );

    }

    const UpdatememberHealth = async (gym) => {
        var um = {
            "id": id,
            "member_id": gym.member_id,
            "chest": gym.chest,
            "biceps": gym.biceps,
            "shoulder": gym.shoulder,
            "waist": gym.waist,
            "thigh": gym.thigh,


        }
        await fetch(setting.api + "/api/UpdatememberHealth",
            {
                body: JSON.stringify(um),
                method: "post",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(data => {
                getMemberHealthByMemberId();
                setMember(um);
                reset(formValues => ({
                    ...formValues,


                }))
            }

            );
        navigate('/healthList');
    }

    useEffect(() => {
        getMemberHealthByMemberId();

    }, []);

    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Header title="Update Member Health" />
                <div className="container-fluid py-4 main">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(UpdatememberHealth)}>
                                <Row>
                                    <Col sm={6} xs={12}>


                                        <div className=" box1  mt-2 text">


                                            <div className="mt-2 text">
                                                <h5 className="info">Member Information</h5>

                                                <div className="h6 mt-1 text">
                                                    <i className="ni business_briefcase-24 mr-2"></i><b>Name -</b> {memberInfo && memberInfo.name}
                                                </div>
                                                <div className="h6 mt-2 text">
                                                    <i className="ni business_briefcase-24 mr-2"></i><b>Email -</b> {memberInfo && memberInfo.email}
                                                </div>
                                                <div className="h6 mt-2 text">
                                                    <i className="ni business_briefcase-24 mr-2"></i><b>Phone No. -</b> {memberInfo && memberInfo.phone}
                                                </div>
                                            </div>
                                        </div>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Chest</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Chest" {...register("chest")} />
                                            {errors.chest && <p>{errors.chest.message}</p>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Shoulder</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Shoulder" {...register("shoulder")} />
                                            {errors.shoulder && <p>{errors.shoulder.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col sm={6} xs={12}>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Biceps</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Biceps" {...register("biceps")} />
                                            {errors.biceps && <p>{errors.biceps.message}</p>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Waist</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Waist" {...register("waist")} />
                                            {errors.waist && <p>{errors.waist.message}</p>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Thigh</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Thigh" {...register("thigh")} />
                                            {errors.thigh && <p>{errors.thigh.message}</p>}
                                        </Form.Group>

                                        <Button type="submit" className="btn bg-gradient-primary  pull-right btnsize" >Update</Button>


                                    </Col>

                                </Row>

                            </Form>

                        </Card.Body>
                    </Card>



                </div>
                <Footer />
            </main>
        </>
    );
}

export default UpdateHealth;