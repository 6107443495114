import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import Widget from "../Component/Widget";

const Dashboard = () => {
    return (
        <>

            <div class="min-height-300 bg-primary position-absolute w-100"></div>
            <Sidebar />
            <main class="main-content position-relative border-radius-lg ">
                <Header title="Dashboard" />
                <div class="container-fluid py-4 ">
                    <Widget />


                    <Footer />
                </div>

            </main>





        </>
    );
}

export default Dashboard;